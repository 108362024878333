/* contact custom page */
#contact-Section.body-section {
  background-color: #ce996b;
}

.contact-background {
  border-radius: 20% 50% 20% 20%;
  background: linear-gradient(to bottom, #fff5ec, #fff5ec90);
  height: 100vh;
  width: 100%;
  position: relative;
  height: 90%;
}

.contact-background .body-container-flex {
  justify-content: center;
}

.contact-background h1 {
  text-shadow: 0px 0px 1px #fff5ec;
  color: #7a3900;
  text-transform: uppercase;
  margin: 5% 0 0% 0%;
}

.contact-background h2 {
  text-shadow: 0px 0px 1px #fff5ec;
  color: #a4632c;
}

.contact-background p {
  font-size: 2dvh;
  text-align: left;
  color: #7a3900;
  margin: 1% 2% 4% 2%;
}

.contact-background a {
  text-decoration: none;
  font-size: 2dvh;
  text-align: left;
  color: #7a3900;
  margin: 1% 2% 4% 2%;
}

.contact-background a:hover {
  text-decoration: none;
  font-size: 3dvh;
  text-align: left;
  color: red;
  margin: 1% 2% 4% 2%;
}

.contact-background .text-body {
  display: inline;
  width: 60%;
  position: relative;
}

.contact-background .paragraphs-body {
  text-align: center;
  display: inline;
}

.contact-background .images {
  border-radius: 12%;
  height: 30%;
  border: transparent solid 3px;
}

.contact-background .image1 {
  background: url("../Images/jobsite/p-fence1.jpeg") no-repeat center;
  background-size: 100%;
}

/* Contact Form */

.textarea-container {
  position: relative;
  margin-bottom: 1dvh; /* Adjust margin as needed */
}

label {
  position: absolute;
  top: 2dvh;
  left: 1dvh;
  color: #fff5ec; /* Label font color */
  pointer-events: none;
  transition: 0.2s ease-out;
}

input,
textarea {
  width: 100%;
  padding: 1dvh;
  background-color: #a4632c; /* Brown background color */
  color: #c5c4d1; /* White font color */
  border: none;
  border-radius: 0.5dvh;
  resize: none;
  margin-top: 2dvh; /* Adjust margin as needed */
}

button {
  padding: 1dvh;
  background-color: #fff5ec; /* Brown background color */
  color: #7a5e00; /* White font color */
  box-shadow: 1px 1px 2px #100b55;
  border-radius: 0.5dvh;
  resize: none;
  margin-top: 0dvh; /* Adjust margin as needed */
}

button:hover {
  padding: 1dvh;
  background-color: #7a3900; /* Brown background color */
  color: #fff5ec; /* White font color */
  box-shadow: 1px 1px 2px #100b55;
  border-radius: 0.5dvh;
  resize: none;
  margin-top: 0dvh; /* Adjust margin as needed */
}

input:focus + label,
textarea:focus + label,
input:not(:placeholder-shown) + label,
textarea:not(:placeholder-shown) + label {
  top: -1dvh;
  font-size: max(2dvh, 2dvw);
  color: #7a3900;
}
