* {
  margin: 0;
  font-size: 1rem;
}

html {
  font-family: "Merriweather", serif;
  text-decoration: none;
  scroll-behavior: smooth;
}

body {
  background: black;
  margin-left: auto;
  margin-right: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  z-index: 52;
  font-size: max(3dvw, 4dvh);
  color: #f8e2c5;
  text-transform: uppercase;
}

h2 {
  font-family: "Bebas Neue", sans-serif;
  color: #8d473a;
  line-height: 1.1;
  letter-spacing: 2px;
  font-size: max(2.5dvw, 3.5dvh);
}

p {
  font-size: 2.5dvh;
  text-align: left;
  color: #f8e2c5;
}

/* App Scroller */

#appbody {
  height: 89dvh;
}

.appbody-scroller {
  height: 89dvh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}
.appsection-scroller {
  scroll-snap-align: start;
  transition: scroll-snap-align 0.3s ease-in-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Icons */
.icons {
  border-radius: 50%;
}

.fbicon:hover {
  box-shadow: 0px 0px 3px 3px #0004ff;
}

.igicon:hover {
  box-shadow: 0px 0px 3px 3px rgba(255, 0, 255, 0.8);
}
