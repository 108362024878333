/* Picture Section */

.body-container-flex {
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
}

.picture-gallery {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 40%;
  margin: 1dvw;
  padding: 1dvh;
  background: radial-gradient(#b18d5e35 80%, transparent);
  border-radius: 12%;
}

.picture-gallery .images {
  border-radius: 12%;
  height: 30%;
  border: transparent solid 3px;
}

.images.expanded {
  border-radius: 0;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: 1000;
}

.picture-gallery .images:hover {
  cursor: pointer;
  border: rgba(255, 0, 0, 0.123) solid 3px;
}

.picture-gallery:hover {
  cursor: pointer;
}
