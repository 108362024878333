.footer {
  background-color: black;
}

.footchevdown {
  background-color: black;
  height: 5dvh;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: block;
}

@keyframes MarginAnimation {
  100% {
    margin-right: 13dvw;
  }
  95% {
    margin-right: 20dvw;
  }
  0% {
    margin-right: 20dvw;
  }
}

.footerdata {
  animation: MarginAnimation 20s linear forwards;
  margin-right: 20dvw;
  background-color: black;
  color: white;
  text-align: center;
  height: 6dvh;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.footer p {
  justify-content: center;
  font-size: 1dvh;
}

.icongroup {
  z-index: 53;
  margin-right: 6vw;
  height: 5dvh;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
}

.icongroup img {
  height: 2dvh;
}

@keyframes BackgroundChangeAnimation {
  100% {
    background-color: black;
    width: 4dvw;
    height: 2dvh;
  }
  90% {
    background-color: #8d473a;
    width: 21dvw;
    height: 15dvh;
  }
  0% {
    background-color: #8d473a;
  }
}

.footer-phonebox {
  z-index: 50;
  position: fixed;
  right: -1dvw;
  animation: BackgroundChangeAnimation 20s linear forwards;
  background-color: black;
  bottom: 0dvh;
  width: 21dvw;
  height: 15dvh;
  border-radius: 50%;
}
