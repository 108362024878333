.body-section {
  font-family: "Lato", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 89dvh;
  text-align: center;
}

.body-h1-padding {
  padding-bottom: 60%;
}

.body-hide-feature {
  display: none;
}

.body-chev-bar-black {
  background-color: black;
}

.body-chevs {
  height: 5dvh;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  display: block;
}

.body-hide-feature {
  display: none;
}

.body-chevup {
  fill: black;
}

.brickbackground {
  content: " ";
  height: 3vh;
  background: url(../Images/brick-outline.png) left top repeat-x;
  background-size: contain;
}

/* Rotate Animation Call me Container */

@keyframes rotateAnimation {
  100% {
    transform: translateY(0dvh);
    background-color: black;
    border: 1px black solid;
  }
  99% {
    transform: translateY(2dvh);
    background-color: #b18d5e;
    border: 1px #b18d5e solid;
  }
  98% {
    transform: translateY(0dvh);
  }
  97% {
    transform: translateY(4dvh);
  }
  96% {
    transform: translateX(0dvw) translateY(-80dvh);
  }
  95% {
    transform: translateX(0dvw) translateY(-80dvh);
  }
  5% {
    transform: translateX(0dvw) translateY(-80dvh);
  }
  4% {
    transform: translateX(-4dvw) translateY(-80dvh);
  }
  3% {
    transform: translateX(0dvw) translateY(-80dvh);
  }
  2% {
    transform: translateX(-10dvw) translateY(-80dvh);
  }
  1% {
    transform: translateX(0px) translateY(-80dvh);
  }
  0% {
    transform: translateX(-100vw) translateY(-80dvh);
  }
}

@keyframes colorAnimation {
  0%,
  100% {
    color: red;
  }
  50% {
    color: #f8e2c5;
  }
}

/* Call me Container */
.call-me-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20dvw;
  height: 14dvh;
  position: fixed;
  bottom: -1dvh;
  right: -1dvh;
  padding: 10px;
  border: #8d693a65 2px solid;
  background: url("../icons/phonebrown.svg") no-repeat center;
  background-size: 50% 75%;
  background-repeat: no-repeat;
  background-color: #b18d5e;
  border-radius: 50%;
  animation: rotateAnimation 20s linear forwards;
  /* Fade out */
  opacity: 1;
  z-index: 51; /* Ensure it appears on top of other elements */
}

/* Adjust the settings of the Call-me-Container */

.call-me-container:hover a {
  animation: colorAnimation 3s linear infinite;
}

.call-me-container.fade-out {
  opacity: 0;
}

.call-me-container.hidden {
  display: none;
}

.call-me-link {
  text-decoration: none;
  flex: content;
  flex-direction: row;
  position: static;
  text-align: center;
  align-items: center;
}

#call-me-heading {
  color: #f8e2c5;
  text-shadow: 2px 2px 4px black;
  font-size: 3dvw;
  font-weight: bold;
}

.call-me-subheading,
.call-me-number {
  color: #f8e2c5;
  text-shadow: 2px 2px 4px black;
  text-decoration: none;
  font-size: 2.5dvw;
  font-weight: bold;
}

/* Text */

.thin-line {
  border-bottom: #8d693a solid 2px;
  width: 30px;
}

.text-body {
  padding: 0% 0% 0% 4%;
  width: 60%;
  text-align: left;
}

.p1text {
  padding: 5% 3% 0% 0%;
  position: relative;
}

.p2text {
  padding: 5% 2% 0% 0%;
  position: relative;
}
.p3text {
  padding: 5% 1% 5% 0%;
  position: relative;
}
