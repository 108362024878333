/* Dropdown.css */

.HeaderLinkBar {
  background-color: black;
}

.mobilebar {
  display: none;
}

.headerdropdown {
  padding: 0;
  margin-top: 1dvh;
  margin-bottom: 1dvh;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.mobilebar.topbarshow {
  display: grid;
  border-bottom: 1px solid white;
}

.zondicon {
  margin-right: 4px;
  height: 3dvw;
}

.colons {
  font-style: normal;
  all: none;
  color: white;
  font-size: 3dvw;
}

.dropdown-link {
  border: black 1px solid;
  text-decoration: none;
  color: white;
  font-size: 3dvw;
  word-spacing: normal;
}

.dropdown-link:hover {
  border-radius: 60%;
  border: rgba(255, 0, 0, 0.3) 1px solid;
  background-color: rgba(0, 0, 255, 0.3);
}

/* Menu Options */

.menudropdown {
  padding: 0;
  margin-left: 1dvh;
  list-style: none;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: space-around;
  overflow: auto; /* Add scrollbars if items overflow */
  flex-direction: row; /* Default direction */
}

.menu-link {
  font-size: 3dvh;
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 600px) {
  .menudropdown {
    flex-direction: column; /* Change direction for mobile screens */
    align-items: center; /* Align items in the center */
  }

  .menudropdown li {
    text-align: center;
    width: 100%; /* Full width for mobile screens */
  }
}

.menu-link:hover {
  border-radius: 60%;
  border: rgba(255, 0, 0, 0.3) 1px solid;
  background-color: rgba(0, 0, 255, 0.3);
}

.iconGroup a {
  padding-right: 1dvw;
  justify-content: space-around;
}

#upanddown.icondown {
  display: block;
}

#upanddown.iconup {
  display: block;
}

#upanddown {
  height: 5dvh;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
