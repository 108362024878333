.TitleBar {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.siteLogo {
  width: 100%;
  background: #cf996b;
  background-image: url("../../public/logo-no-white.png");
  background-size: contain;
  background-repeat: no-repeat;
}

@keyframes moveTitles {
  100% {
    margin-right: 0;
  }
  95% {
    margin-right: 15%;
  }
  0% {
    margin-right: 15%;
  }
}

.siteLogo h1 {
  animation: moveTitles 20s linear forwards;
  text-shadow: 2px 3px 1px #7a3900;
  margin-bottom: 1vh;
  color: #f8d5b7;
  font-size: 6dvw;
}
.siteLogo h2 {
  text-shadow: 2px 2px 1px #f8d5b7;
  margin-top: 1dvh;
  animation: moveTitles 20s linear forwards;
  font-size: 5vw;
  text-decoration: underline;
  font-weight: 900;
  letter-spacing: 10px;
  color: #7a3900;
}

.header {
  display: flex;
  flex-wrap: wrap;
  padding-right: 3rem;
  font-family: "Bebas Neue", sans-serif;
  justify-content: right;
  line-height: 1.1;
  letter-spacing: 1.5px;
}
