/* About custom page */
#about-Section.body-section {
  background-color: #cf996b;
  width: 100%;
  animation: changeBackground 10s infinite; /* Adjust the duration and iteration count as needed */
  background-size: cover;
  background-position: center;
}

@keyframes changeBackground {
  0%,
  100% {
    background-image: url("../Images/jobsite/p-tools1.jpeg");
  }
  66.66% {
    background-image: url("../Images/jobsite/p-kitchen1.jpeg");
  }
  33.33% {
    background-image: url("../Images/jobsite/p-shed1.jpeg");
  }
}

#about-Section .body-headers h1 {
  text-shadow: 0px 0px 1px #fff5ec;
  color: #7a3900;
}
#about-Section .body-headers h2 {
  text-shadow: 0px 0px 1px #fff5ec;
  color: #a4632c;
}

#about-Section p {
  padding-left: 0;
  color: #7a3900;
}

.about-background {
  border-radius: 0% 50% 50% 0%;
  background: linear-gradient(to right, #fff5ecfe, #fff5ec98);
  height: 80%;
}
.about-background .body-container-flex {
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.about-background .text-body {
  width: 80%;
}
.about-background .paragraphs-body {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 90%;
}
