/* Services custom page */

#servicesSection {
  background-color: #fff5ec;
}

#servicesSection h1 {
  text-shadow: 0px 0px 1px #a4632c;
  color: #fff5ec;
}

#servicesSection .thin-line {
  border-color: #fff5ec;
}
#servicesSection h2 {
  text-shadow: 0px 0px 1px #a4632c;
  color: #f8d5b7;
}

#servicesSection .paragraphs-body {
  height: 70%;
}

#servicesSection ul {
  padding: 0;
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#servicesSection ul li {
  font-size: 2dvh;
  text-decoration: none;
  color: #fff5ec;
}

.services-background {
  background: linear-gradient(to right, #7a3900, #7a3900cc);
  border-radius: 1% 50% 50% 1%;
  position: relative;
  height: 80%;
}

#servicesSection .picture-gallery .image1 {
  background: url("../Images/jobsite/p-shed1.jpeg") no-repeat center;
  background-size: 100%;
}

#servicesSection .picture-gallery .image2 {
  background: url("../Images/jobsite/p-windows1.jpeg") no-repeat center;
  background-size: 200%;
}

#servicesSection .picture-gallery .image3 {
  background: url("../Images/jobsite/p-windows4.jpeg") no-repeat center;
  background-size: 120%;
}

/* Kitchen custom page */
#kitchenSection {
  background-color: #c5c4d1;
}

#kitchenSection h1 {
  text-shadow: 0px 0px 1px #595490;
  color: #c5c4d1;
}

#kitchenSection .thin-line {
  border-color: #c5c4d1;
}

#kitchenSection h2 {
  text-shadow: 0px 0px 1px #595490;
  color: #8986ac;
}

#kitchenSection p {
  padding-left: 0;
  color: #c5c4d1;
}

.kitchen-background {
  background: linear-gradient(to right, #100b55, #595490);
}

#kitchenSection .picture-gallery .image1 {
  background: url("../Images/jobsite/p-kitchen1.jpeg") no-repeat center;
  background-size: 100%;
}

#kitchenSection .picture-gallery .image2 {
  background: url("../Images/jobsite/p-kitchen2.jpeg") no-repeat center;
  background-size: 100%;
}

#kitchenSection .picture-gallery .image3 {
  background: url("../Images/jobsite/p-kitchen4.jpeg") no-repeat center;
  background-size: 120%;
}

/* Bathroom custom page */

#bathroomSection {
  background-color: #c5c4d1;
}

#bathroomSection h1 {
  text-shadow: 0px 0px 1px #595490;
  color: #c5c4d1;
}

#bathroomSection .thin-line {
  border-color: #c5c4d1;
}

#bathroomSection .text-body h2 {
  text-shadow: 0px 0px 1px #595490;
  color: #8986ac;
}

#bathroomSection p {
  padding-left: 0;
  color: #c5c4d1;
}

.bathroom-background {
  background: linear-gradient(to right, #100b55, #595490);
}

#bathroomSection .picture-gallery .image1 {
  background: url("../Images/jobsite/p-bathroom2.jpeg") no-repeat center;
  background-size: 100%;
}

#bathroomSection .picture-gallery .image2 {
  background: url("../Images/jobsite/p-bathroom3.jpeg") no-repeat center;
  background-size: 100%;
}

#bathroomSection .picture-gallery .image3 {
  background: url("../Images/jobsite/p-bathroom5.jpeg") no-repeat center;
  background-size: 100%;
}

/* garage custom page */
#garageSection {
  background-color: #fffbec;
}

#garageSection h1 {
  text-shadow: 0px 0px 1px #ceb86b;
  color: #fffbec;
}

#garageSection .thin-line {
  border-color: #fffbec;
}

#garageSection h2 {
  text-shadow: 0px 0px 1px #ceb86b;
  color: #f8e9b7;
}

#garageSection p {
  padding-left: 0;
  color: #fffbec;
}

.garage-background {
  background: linear-gradient(to right, #7a5e00, #a4882c);
}

#garageSection .picture-gallery .image1 {
  background: url("../Images/jobsite/p-basement1.jpeg") no-repeat center;
  background-size: 120%;
}

#garageSection .picture-gallery .image2 {
  background: url("../Images/jobsite/p-addition1.jpeg") no-repeat center;
  background-size: 100%;
}

#garageSection .picture-gallery .image3 {
  background: url("../Images/jobsite/p-addition3.jpeg") no-repeat center;
  background-size: 120%;
}

/* fences custom page */
#fencesSection {
  background-color: #fffbec;
}

#fencesSection h1 {
  text-shadow: 0px 0px 1px #ceb86b;
  color: #fffbec;
}

#fencesSection .thin-line {
  border-color: #fffbec;
}

#fencesSection h2 {
  text-shadow: 0px 0px 1px #ceb86b;
  color: #f8e9b7;
}

#fencesSection p {
  padding-left: 0;
  color: #fffbec;
}

.fences-background {
  background: linear-gradient(to right, #7a5e00, #a4882c);
}

#fencesSection .picture-gallery .image1 {
  background: url("../Images/jobsite/p-fence2.jpeg") no-repeat center;
  background-size: 120%;
}

#fencesSection .picture-gallery .image2 {
  background: url("../Images/jobsite/p-deck5.jpeg") no-repeat center;
  background-size: 120%;
}

#fencesSection .picture-gallery .image3 {
  background: url("../Images/jobsite/p-deck3.jpeg") no-repeat center;
  background-size: 120%;
}

/* Windows custom page */
#windowsSection {
  background-color: #fffbec;
}

#windowsSection h1 {
  text-shadow: 0px 0px 1px #ceb86b;
  color: #fffbec;
}

#windowsSection .thin-line {
  border-color: #fffbec;
}

#windowsSection h2 {
  text-shadow: 0px 0px 1px #ceb86b;
  color: #f8e9b7;
}

#windowsSection p {
  padding-left: 0;
  color: #fffbec;
}

.windows-background {
  background: linear-gradient(to right, #7a5e00, #a4882c);
}

#windowsSection .picture-gallery .image1 {
  background: url("../Images/jobsite/p-windows1.jpeg") no-repeat center;
  background-size: 200%;
}

#windowsSection .picture-gallery .image2 {
  background: url("../Images/jobsite/p-windows3.jpeg") no-repeat center;
  background-size: 120%;
}

#windowsSection .picture-gallery .image3 {
  background: url("../Images/jobsite/p-windows4.jpeg") no-repeat center;
  background-size: 120%;
}

/* Contact custom page */

#contactSection {
  background-color: #8d693a;
}

.contact-background {
  background: linear-gradient(to right, #b18d5e, transparent);
}
